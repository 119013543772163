import { render, staticRenderFns } from "./CheckboxItem.vue?vue&type=template&id=9251845a&scoped=true"
import script from "./CheckboxItem.vue?vue&type=script&lang=ts"
export * from "./CheckboxItem.vue?vue&type=script&lang=ts"
import style0 from "./CheckboxItem.vue?vue&type=style&index=0&id=9251845a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9251845a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/tty215/develop/indent/nola/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9251845a')) {
      api.createRecord('9251845a', component.options)
    } else {
      api.reload('9251845a', component.options)
    }
    module.hot.accept("./CheckboxItem.vue?vue&type=template&id=9251845a&scoped=true", function () {
      api.rerender('9251845a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/atoms/CheckboxItem.vue"
export default component.exports